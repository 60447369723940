import axios from 'axios'

// 创建实例时设置请求配置的默认值
const service=axios.create({
    // baseURL:'process.env.VUE_APP_BASE_API',  //请求路径， 已经在vue.config.js里面配置了则不用配置
    timeout:5000,
})

// 添加请求拦截器
service.interceptors.request.use(function(config){
    // 在发送请求之前做什么(存token或者设置请求头等)
    config.headers.authorization='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJobmpjX3VzZXIiLCJpYXQiOjE2MTc3NTk1MzUsImV4cCI6MTcxMjM2NzUzNSwibmJmIjoxNjE3NzU5NTM1LCJzdWIiOiIyfHNlcnZlciIsImp0aSI6IjAwNGY1ZmIyZTE0NGI0ZWE2YzVkNWU2MDhkMDUxNTBlIn0.gSFCJeC-vaKKz-is_15S2ineVTSKQ7hnPHyCPyk8His'
    return config;
},function (error){
    // 对请求错误做些什么
    return Promise.reject(error);
})

// 添加响应拦截
service.interceptors.response.use(function (response){
    // 对响应数据做点什么（直接返回请求的数据）
    return response
},function (error){
    // 响应失败 （如登录失败，给出提示或者跳回登录页面）
    return Promise.reject(error)
})


// 输出配置
export default service