    import axios from './axios'  //引入请求配置

    // 请求具体的接口数据
    export function shopsapi() {    //商家列表
        return axios({
            url: '/shops',
            method: 'get'
        })
    }

    export function goodsapi(data) {    //权益产品
        return axios({
            url: '/goods',
            method: 'get',
            params: data
        })
    }

    export function payment() {    //支付订单
        return axios({
            url: '/payment',
            method: 'get'
        })
    }

    export function couponslq(data) {  //用户领取卡券
        return axios({
            url: '/coupons',
            method: 'post',
            data
        })
    }

    export function pagelife(data) {  //页面计时
        return axios({
            url: '/pagelife',
            method: 'post',
            data
        })
    }
	
    export function orderstatus(data) {  //订单状态
        return axios({
            url: '/heshenghuo/order/status',
            method: 'post',
            data
        })
    }
